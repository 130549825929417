import React from 'react'
import {graphql} from "gatsby";

import Layout from '../../components/Layout'
import ArticlesRollBlog from '../../components/ArticlesRollBlog'
import PageHeading from '../../components/PageHeading'

const BlogPage = (props) =>
    (
        <Layout title={props.data.markdownRemark.frontmatter.title}
                description={props.data.markdownRemark.frontmatter.description}>
            <PageHeading heading={props.data.markdownRemark.frontmatter.title}
                         className="title is-size-3 is-size-4-touch is-size-4-mobile"/>
            <section className="section">
                <div className="container">
                    <div className="content">
                        <ArticlesRollBlog/>
                    </div>
                </div>
            </section>
        </Layout>
    );

export default BlogPage;

export const AllArticlesPageQuery = graphql`
    query AllArticlesPageQuery {
        markdownRemark(frontmatter: { templateKey: { eq: "blog-page" } }) {
            frontmatter {
                title
                description
            }
        }
    }
`
